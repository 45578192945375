import React from "react";
import {
  FaDribbbleSquare,
  FaFacebookSquare,
  FaGithubSquare,
  FaInstagram,
  FaLinkedin,
  FaTwitterSquare,
  FaArrowUp
} from "react-icons/fa";
import Logo from "../assets/mmsili-online-white 1.png";
import Apple from "../assets/IOS.png";
import Android from "../assets/Android.png";
import RoundButton from "../components/RoundButton";

const Footer = () => {
  return (
    <>
      {/* Desktop footer */}
      <div className="max-w-auto  mx-auto py-16 px-4 lg:grid-cols-3 gap-8  bg-black text-white  hidden md:block">
        {/* Subscription email input */}
        {/* <div className="justify-center mb-8  align-middle">
          <div className='relative mb-8 align-middle'>
            <input type="text" className="w-full sm:w-[100%] h-12 pl-6 sm:pl-14 bg-white rounded-full border-2 border-cyan-600 text-cyan-800 placeholder-cyan-800 placeholder-opacity-80 placeholder-[40px] font-medium leading-loose" placeholder="Enter your email, to subscribe" />
            <div className="absolute top-0 right-0 h-full w-12 sm:w-16 flex items-center justify-center bg-black">
              <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.5 15.5l5.5 5.5"></path>
                <circle cx="11" cy="11" r="8"></circle>
              </svg>
            </div>
          </div>
        </div> */}
        {/* <center>  <SubscribeInput/></center> */}

        <div className="lg:col-span-2 flex justify-between mt-6">
          {/* <div>
            <img src={Logo} alt='Company Logo' className='h-10 mr-4' />
            <p className='py-4'>
              <span>
                <img src={Apple} alt="Apple Icon" className="w-15 h-10 mx-2 inline-block" />
              </span>
              <span>
                <img src={Android} alt="iOS Icon" className="w-15 h-10 mx-2 inline-block" />
              </span>
            </p>
            <div className='flex md:w-[75%] my-6'>
              <FaFacebookSquare size={30} />
              <FaInstagram size={30} />
              <FaTwitterSquare size={30} />
              <FaGithubSquare size={30} />
              <FaDribbbleSquare size={30} />
            </div>
          </div> */}
          <div>
            <div>
              <h6 className="font-medium text-gray-400">Contacts</h6>
              <ul>
                <li className="py-2 text-sm">
                  +265 999 776 931 / +265 888 776 931
                </li>
                <li className="py-2 text-sm">info@staticcomputersinc.com</li>
                <li className="py-2 text-sm">Area 47, Sector 4</li>
              </ul>
            </div>
          </div>
          <div>
            <ul>
              <li className="py-2 text-sm">Home</li>
              <li className="py-2 text-sm">Service</li>
              <li className="py-2 text-sm">Potfolio</li>
              <li className="py-2 text-sm">About</li>
              <li className="py-2 text-sm">Contacts</li>
            </ul>
          </div>
          <div>
            <ul className="space-y-2">
              <li className="flex items-center py-2 text-sm">
                <FaFacebookSquare size={30} className="mr-2" />
                Facebook
              </li>
              <li className="flex items-center py-2 text-sm">
                <FaTwitterSquare size={30} className="mr-2" />
                Twitter
              </li>
              <li className="flex items-center py-2 text-sm">
                <FaLinkedin size={30} className="mr-2" />
                Linkedin
              </li>
            </ul>
          </div>
          <div>
           <h6 className="font-medium text-white rounded-full bg-[#3287C1] h-10 w-10 flex items-center justify-center">
              <FaArrowUp className="" />
            </h6>
          </div>
        </div>
        {/* Copyright */}
        <div className="flex justify-center mt-6">
          <p className="text-sm text-gray-400">
            © {new Date().getFullYear()} - Static Computers Inc All rights
            reserved .
          </p>
        </div>
      </div>

      {/* Mobile footer */}
      <div className="mx-auto py-16 px-4 grid gap-8  bg-black text-white md:hidden">
       

        <div className="max-w-screen-lg mx-auto align-middle">
          {/* <img src={Logo} alt="Company Logo" className="h-10 mr-4" /> */}
         
          <div className="flex justify-center md:w-[75%] my-6">
            <FaFacebookSquare size={30} />
            <FaInstagram size={30} />
            <FaTwitterSquare size={30} />
            {/* <FaGithubSquare size={30} />
            <FaDribbbleSquare size={30} /> */}
          </div>
        </div>
        <div className="md:flex md:justify-between md:max-w-screen-lg mx-auto mt-6">
        
          <div>
            <ul>
              <li className="py-2 text-sm">Home</li>
              <li className="py-2 text-sm">Service</li>
              <li className="py-2 text-sm">Potfolio</li>
              <li className="py-2 text-sm">About</li>
              <li className="py-2 text-sm">Contacts</li>
            </ul>
          </div>
          
            <div>
              <ul>
                <li className="py-2 text-sm">
                  +265 999 776 931 / +265 888 776 931
                </li>
                <li className="py-2 text-sm">info@staticcomputersinc.com</li>
                <li className="py-2 text-sm">Area 47, Sector 4</li>
              </ul>
            </div>
        </div>

         <div>
           <h6 className="font-medium text-white rounded-full bg-[#3287C1] h-10 w-10 flex items-center justify-center">
              <FaArrowUp className="" />
            </h6>
          </div>

        {/* Copyright */}
        <div className="flex justify-center mt-6">
          <p className="text-sm text-gray-400">
            © {new Date().getFullYear()} - Static Computers Inc. All rights
            reserved.
          </p>
        </div>

      </div>
    </>
  );
};

export default Footer;
