



import React from "react";
import { Fade } from "react-reveal";
import {
  FaDribbbleSquare,
  FaFacebookSquare,
  FaGithubSquare,
  FaInstagram,
  FaLinkedin,
  FaTwitterSquare,
  FaArrowUp,
  FaPhone,
  FaEnvelope,
  FaMapMarker,
} from "react-icons/fa";

const Contacts = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-10 py-10 bg-[#3287C1]">
      {/* Contact Form */}
      <Fade left>
        <div className="bg-gray-100 p-8 rounded relative m-8">
          {/* First Name Input */}
          <input
            type="text"
            placeholder="First Name"
            className="block w-full border rounded-lg border-gray-300  mb-2 p-2"
          />

          {/* Last Name Input */}
          <input
            type="text"
            placeholder="Last Name"
            className="block w-full border border-gray-300 rounded-lg mb-2 p-2"
          />

          {/* Email Input */}
          <input
            type="text"
            placeholder="Email"
            className="block w-full border border-gray-300 rounded-lg mb-2 p-2"
          />

          {/* Message Textarea */}
          <textarea
            rows="5"
            placeholder="Message"
            className="block w-full border border-gray-300 rounded-lg mb-4 p-2"
          ></textarea>

          {/* Send Button */}
          <button className="bg-[#3287C1] text-white py-2 px-4 rounded-lg float-left">
            Send Now
          </button>
        </div>
      </Fade>

      <Fade right>
        <div className="bg-gray-100 p-12 rounded max-w-[400px] m-8 flex flex-col space-y-6">
          {/* Contact Details */}
          {/* ... same code as before ... */}

        <div className="flex items-center">
          <FaPhone size={30} className="text-cyan-600 mr-4" />
          <div>
            <div className="text-cyan-600 text-xl font-bold leading-tight">
              Call Anytime
            </div>
            <div className="text-blue-950 text-base font-medium leading-tight">
              + 265 999 776 931
              <br />+ 265 999 776 931
            </div>
          </div>
        </div>

        <div className="flex items-center ">
          <FaEnvelope size={30} className="text-cyan-600 mr-4" />
          <div>
            <div className="text-cyan-600 text-xl font-bold leading-tight">
              Send Email
            </div>
            <div className="text-blue-950 text-base font-medium leading-tight">
              info@staticcomputersinc.com
            </div>
          </div>
        </div>

        <div className="flex items-center">
          <FaMapMarker size={30} className="text-cyan-600 mr-4" />
          <div>
            <div className="text-cyan-600 text-xl font-bold leading-tight">
              Visit Us
            </div>
            <div className="text-blue-950 text-base font-medium leading-tight">
              area 47, sector 4,
              <br />
              plot number 42
            </div>
          </div>
        </div>

        <div className="flex items-center justify-center font-bold">
          <span className="text-black  font-black">Follow us</span>
        </div>

        {/* Add more contact details as needed */}
        <div className="flex space-x-8">
          {/* Replace the links below with your social media profile links */}
          <a href="#" className="text-cyan-600 text-xl hover:text-cyan-100 ">
            <FaLinkedin size={70} />
          </a>
          <a href="#" className="text-cyan-600 text-xl hover:text-cyan-100">
            <FaFacebookSquare size={70} />
          </a>
          <a href="#" className="text-cyan-600 text-xl hover:text-cyan-100">
            <FaTwitterSquare size={70} />
          </a>
        </div>
    
        </div>
      </Fade>
    </div>
  );
};

export default Contacts;
