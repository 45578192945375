import React, { useState } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import Logo from '../assets/logo.png';

import { Fade } from 'react-reveal';
const Navbar = () => {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <>
      <div className="flex items-center justify-between bg-white p-4  px-8 mx-10 my-2">
        {/* Company Logo (replace 'logo.png' with your logo file) */}
        <div className="flex items-center">
          <img src={Logo} alt="Company Logo" className="h-24 w-45" />
          {/* <span className="text-blue-500 text-lg font-bold ml-2">Company Name</span> */}
        </div>

        {/* Navigation Links */}
        <div className="hidden md:flex text-[#3287C1] text-2xl">
          <a href="#" className="text-[#3287C1] font-medium ml-12">
            Home
          </a>
          <a href="#" className="text-[#3287C1] font-medium ml-12">
            About
          </a>
          <a href="#" className="text-[#3287C1] font-medium ml-12">
            Services
          </a>
          <a href="#" className="text-[#3287C1] font-medium ml-12">
            Portfolio
          </a>
          <a href="#" className="text-[#3287C1] font-medium ml-12">
            Contacts
          </a>
        </div>
      </div>

      {/* Navigation Links */}
      <div className="relative">
        
          <div className="flex md:hidden fixed bottom-0 left-0 w-full bg-white p-2 justify-center space-x-4 text-[#3287C1] z-50">
            <a href="#" className="text-[#3287C1] font-medium">
              Home
            </a>
            <a href="#" className="text-[#3287C1] font-medium">
              About
            </a>
            <a href="#" className="text-[#3287C1] font-medium">
              Services
            </a>
            <a href="#" className="text-[#3287C1] font-medium">
              Portfolio
            </a>
            <a href="#" className="text-[#3287C1] font-medium">
              Contacts
            </a>
          </div>
      </div>
    </>
  );
};

export default Navbar;

    