import React from 'react';
import image2 from "../assets/main image.png";
import image1 from "../assets/mobile photo.png";

// Mobile View
const MobileView = () => {
  return (
    <div className="flex justify-center items-center h-screen space-y-4">
      <div className="w-74 h-auto p-4 bg-white">
        <img
          className="w-full h-full object-contain"
          src={image1}
          alt="Image"
        />
      </div>
    </div>
  );
};

// Desktop View
const DesktopView = () => {
  return (
    <div className="flex justify-center items-center h-screen">
      <div className="w-194 h-194 p-4 m-12 bg-white">
        <img
          className="w-full h-full object-contain"
          src={image2}
          alt="Image"
        />
      </div>
    </div>
  );
};

const Portfolio = () => {
  // Determine if the screen is in mobile view (screen width <= 768px)
  const isMobileView = window.innerWidth <= 768;

  return isMobileView ? <MobileView /> : <DesktopView />;
};

export default Portfolio;
