import React from 'react';
import { Fade } from 'react-reveal';

const Card = ({ number, title, description }) => {
  return (
    <div className="bg-neutral-100 rounded-2xl p-6">
      <div className="bg-white w-20 h-20 rounded-2xl mb-4 flex justify-center items-center">
        <div className="text-cyan-600 text-5xl font-extrabold">{number}</div>
      </div>
      <div className="text-blue-950 text-xl font-extrabold leading-relaxed mb-4">
        {title}
      </div>
      <div className="bg-cyan-600 h-1.5 mt-4 w-20"></div>
      <div className="text-black text-base font-medium leading-relaxed">
        {description}
      </div>
    </div>
  );
};

const AnimatedCard = ({ number, title, description }) => {
  return (
    <Fade bottom>
      <Card number={number} title={title} description={description} />
    </Fade>
  );
};

const OurValuesComponent = () => {
  const cardsData = [
    {
      number: '01',
      title: 'Customer Satisfaction',
      description:
        'It is our personal responsibility to satisfy and strive to exceed the expectations of our customers',
    },
    {
      number: '02',
      title: 'Accountability',
      description:
        'We take full responsibility for every action and decision made, including the results delivered',
    },
    {
      number: '03',
      title: 'Excellence',
      description:
        'We strive to be excellent in the methods we use to conduct business',
    },
    {
      number: '04',
      title: 'Innovation',
      description:
        'We will continually create and improve our products, services, and processes, for all our customers',
    },
  ];

  return (
    <div className="px-2 m-2 md:m-2">
      <div className="max-w-[95%] px-4 md:px-6 py-6 md:py-10 bg-cyan-600 bg-opacity-10 rounded-lg flex-col justify-start items-center m-1 md:m-8 p-4 md:p-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10">
          {cardsData.map((card, index) => (
            <AnimatedCard
              key={index}
              number={card.number}
              title={card.title}
              description={card.description}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default OurValuesComponent;
