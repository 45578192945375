import React from 'react';
import { Link, Element } from 'react-scroll';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';

import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Experience from './components/Experience';
import ServicesComponent from './components/Services';
import OtherServices from './components/OtherServices';
import Contacts from './components/Contacts';
import Portfolio from './components/Portfolio';
import OurValues from './components/OurValues';
import GridExpatise from './components/Expatise';
import WhatWeDo from './components/WhatWeDo';
import Footer from './components/Footer';
import LayOut from './components/LayOut';

function App() {
  return (
    <div>
      <Navbar />

      <Element name="hero">
        <Hero />
      </Element>

      <Element name="experience">
        <Fade>
          <Experience />
        </Fade>
      </Element>

      <LayOut />

      <Element name="values">
        <Slide left>
          <div className="container-fluid w-full m-2 md:m-2 mt-6">
            <OurValues />
          </div>
        </Slide>
      </Element>

      <WhatWeDo />

      <Element name="expertise">
        <Slide left>
          <div className="container-fluid w-full bg-[#3287C1] m-2 md:m-2 mt-6">
            <h1 className="text-xl text-center mb-4 text-black py-8">\ Services \</h1>
            <h1 className="text-xl text-center mb-4 text-white">Our Expertise</h1>
            <GridExpatise />
          </div>
        </Slide>
      </Element>

      <Element name="portfolio">
        <Fade>
          <div className="container-fluid mx-auto mt-2 py-6">
            <h1 className="text-xl text-center mb-4 text-black">\ Portfolio \</h1>
            <h1 className="text-xl text-center mb-4 text-[#3287C1]">Our Work</h1>
            <Portfolio />
          </div>
        </Fade>
      </Element>

      <Element name="contacts">
        <Fade>
          <div className="container-fluid bg-[#3287C1] mt-6">
            <h1 className="text-xl text-center mb-4 text-white py-6">\ Contacts \</h1>
            <h1 className="text-2xl text-center mb-4 text-white">Hey! Let’s Talk</h1>
            <Contacts />
          </div>
        </Fade>
      </Element>

      <div className="container-fluid mt-24">
        {/* Your content goes here */}
        <Footer />
      </div>
    </div>
  );
}

export default App;
