import React from "react";
import { FaPhone } from "react-icons/fa";
import Call from '../assets/call.png';

const ContactCard = () => {
  return (
    <div className="bg-white shadow-lg rounded-2xl p-3 md:flex md:w-60 bottom-10 mb-[50px]">
      {/* First Column */}
      <div className="md:w-1/4 flex items-center justify-center">
        <h6 class="font-medium text-white rounded-full bg-[#3287C1] h-10 w-10 flex items-center justify-center">
         <img src={Call} alt="Company Logo" className="h-18 w-18" />
        </h6>
      </div>
      <div
        className="md:w-3/4 md:ml-4
      " >
        {/* Second Row */}
        <div className="flex flex-col  text-lg font-extrabold">
          <div>
           <span className="text-[#3287C1]">Customer Service</span>
            <br />
            +265 999 776931
            <br />
            +265 888 776931
          </div>
        </div>

        {/* Third Row */}
        <div className="flex flex-col">
          <div> </div>
        </div>
      </div>
    </div>
  );
};

export default ContactCard;
