import React from "react";
import { FaPhone } from "react-icons/fa";
import Call from "../assets/call.png";

const ContactCardMobile = () => {
  return (
    <div className="bg-white shadow-sm rounded-2xl flex m-6 items-center">
      {/* First Column */}
      <div className="w-1/3 md:w-1/4 flex items-center justify-center h-24 md:h-20">
        <h6 class="font-medium text-white rounded-full bg-[#3287C1] h-16 w-16 flex items-center justify-center">
          <img src={Call} alt="Company Logo" className="h-18 w-20" />
        </h6>
      </div>

      {/* Second Column */}
      <div className="w-3/4 ml-4 p-4">
        {/* First Row */}
        <div className="text-lg  text-[#3287C1]">Customer Service</div>

        {/* Second Row */}
        <div className="flex flex-col ">
          <div className="text-xl font-bold ">
            <a href={`tel:${"+265 999 776931"}`}>{"+265 999 776931"}</a>
            <br />

            <a href={`tel:${"+265 888 776931"}`}>{"+265 888 776931"}</a>
          </div>
        </div>

        {/* Third Row */}
        <div className="flex flex-col font-bold ">
          <div className="text-xl"> </div>
        </div>
      </div>
    </div>
  );
};

export default ContactCardMobile;
