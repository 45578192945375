import React from 'react';
import P1 from "../assets/our-customers/shoprite.png"; // Replace with actual image paths
import P2 from '../assets/our-customers/wewill.png';
import P3 from "../assets/our-customers/mw parliament.png"; // Replace with actual image paths
import P4 from "../assets/our-customers/artikulate.png";
import P5 from "../assets/our-customers/aga.jpg"; // Replace with actual image paths
import P6 from "../assets/our-customers/Malawi Law Society.png";
import P7 from "../assets/our-customers/Malawi Government.png";
import P8 from "../assets/our-customers/Innovatus_Logo.png"; // Replace with actual image paths
import P9 from "../assets/our-customers/AGA.png";
import ContactCard from "../components/ContactCard"
import ContactCardMobile from "../components/ContactCardMobile"


const Experience = () => {
  return (

    //    <div className="container mx-auto px-4 py-8">
    //   {/* First Row */}
    //   <div className="bg-gray-200 rounded-lg p-4 mb-8">
    //     <h2 className="text-xl font-bold">Card Title</h2>
    //   </div>

    //   {/* Second Row */}
    //   <div className="flex items-start justify-between ">
    //     {/* Left Column */}
    //     <div className="flex-shrink-0 mr-4">
    //       <div className="overflow-x-auto whitespace-nowrap">
    //         <img src="image1.jpg" alt="Image 1" className="w-20 h-20 inline-block mr-2" />
    //         <img src="image2.jpg" alt="Image 2" className="w-20 h-20 inline-block mr-2" />
    //         <img src="image3.jpg" alt="Image 3" className="w-20 h-20 inline-block mr-2" />
    //         <img src="image4.jpg" alt="Image 4" className="w-20 h-20 inline-block mr-2" />
    //         <img src="image5.jpg" alt="Image 5" className="w-20 h-20 inline-block mr-2" />
    //         {/* Add more images if needed */}
    //       </div>
    //     </div>

    //     {/* Right Column */}
    //     <div className="w-1/2">
    //       <div className="bg-white rounded-lg p-4">
    //         <h2 className="text-lg font-bold mb-4">Card in Right Column</h2>
    //         <p>Some content goes here...</p>
    //       </div>
    //     </div>
    //   </div>
    // </div>
<>
<div className="container mx-auto py-2">

 {/* First Row */}
  <div className="left-0 bg-white text-[#3287C1] font-bold z-10 mx-0 md:mx-0 hidden md:block ">
    <div className="w-[180px] h-[60px] bg-white rounded-tl-[30px] justify-start items-center inline-flex shadow">
     <div className="text-[#3287C1] text-2xl font-extrabold items-center p-4 md:p-6 mt">Experience</div>

    </div>
  </div>

  {/* First Row */}
  <div className="left-0 bg-white text-[#3287C1] font-bold z-10 mx-0 md:mx-0 md:hidden">
    <div className="w-[140px] h-[40px] bg-white rounded-tl-[30px] justify-start items-center inline-flex shadow">
<div className="text-[#3287C1] text-xl font-extrabold items-center p-4 md:p-6">Experience</div>
    </div>
  </div>

  {/* Second Row */}
  <div className="flex items-start">

    {/* Left Column */}
<div className="flex-shrink-0 w-full md:w-3/4 xl:w-4/5 mr-4 ">
  <div className="scroll-container rounded-tr-[20px] rounded-bl-[20px] rounded-br-[20px] flex justify-start items-center  overflow-x-auto shadow-md ">
    <img className="w-24 h-26 p-2 rounded-lg" src={P1} />
    <img className="w-24 h-26 p-2 rounded-lg" src={P3} />
    <img className="w-24 h-26 p-2 rounded-lg" src={P4} />
    <img className="w-24 h-26 p-2 rounded-lg" src={P5} />
    <img className="w-24 h-26 p-2 rounded-lg" src={P6} />
    
    {/* Add more images if needed */}
    <img className="w-24 h-26 p-2 rounded-lg" src={P2} />
    <img className="w-24 h-26 p-2 rounded-lg" src={P7} />
    <img className="w-24 h-26 p-2 rounded-lg" src={P8} />
    <img className="w-24 h-26 p-2 rounded-lg" src={P9} />
  </div>
</div>


    {/* Right Column */}
    <div className="w-1/2  ml-8 mx-auto hidden md:block bottom-48 "> {/* Added "ml-8" class to increase left margin */}

     <ContactCard/>
    
    </div>
  </div>
</div>
<div className="  md:hidden"> {/* Added "ml-8" class to increase left margin */}

     <ContactCardMobile/>
    
</div>


</>



  );
};

export default Experience;
