import React from "react";
import Laptop from "../assets/Rectangle 23.png";



// Content for the second column
const WhatWeDo = () => {
  return (
    <div className="px-2 m-2 md:m-2">
      <div className="mx-auto grid md:grid-cols-2 max-w-[95%] px-4 md:px-6 py-6 md:py-10 bg-cyan-600 bg-opacity-10 rounded-lg flex-col justify-start items-center m-2 md:m-8 p-4 md:p-8">
        <img
          className="w-[95%] max-w-[800px] mx-auto my-auto rounded-lg"
          src={Laptop}
          alt="/"
        />
        <div className="flex flex-col justify-center mt-4 md:mt-0">
          <p className="text-sm md:text-md">
            <span style={{ color: "black" }}> \ </span>
            <span style={{ color: "#3287C1" }}> What We Do </span>{" "}
            <span style={{ color: "black" }}>\</span>
          </p>
          <p>
            {" "}
            <div className="w-[95%] max-w-[900px] text-cyan-600 text-[24px] md:text-[30px] font-bold capitalize leading-[40px]">
              We Develop Products and offer
              <br />
              services That People Love to Use.
            </div>
          </p>
          <div className="max-w-[95%] md:max-w-[1000px] w-full bg-white rounded-[10px] p-2 md:p-4 mt-4 md:mt-8">
            <div className="grid grid-cols-[1fr,3fr] gap-2 m-2 p-2">
              {/* First Column */}
              <div className="bg-gray-50 rounded-[10px] p-2">
                {/* Additional div in the first row */}
                <div className="w-[80px] h-[80px] bg-cyan-600 bg-opacity-10 rounded-[10px] flex justify-center items-center">
                  <div className="text-black text-[20px] md:text-[24px] font-bold leading-tight">
                    #1
                  </div>
                </div>

                {/* Content for the first column */}
                
              </div>

              {/* Second Column */}
              <div className="bg-gray-200 rounded-[10px] p-2">
                {/* Content for the second column */}
                <p className="text-l md:text-md text-cyan-600 ">
                  Our mission
                </p>
                <p className="text-sm md:text-md text-blue-950 ">
                  Connecting People’s Life to Technology with solutions beyond imagination and expectations at the lowest possible prices
                </p>
              </div>
            </div>
            <div className="grid grid-cols-[1fr,3fr] gap-2 m-2 p-2">
              {/* First Column */}
              <div className="bg-gray-50 rounded-[10px] p-2 ">
                {/* Additional div in the first row */}
                <div className="w-[80px] h-[80px] bg-cyan-600 bg-opacity-10 rounded-[10px] flex justify-center items-center">
                  <div className="text-black text-[20px] md:text-[24px] font-bold leading-tight">
                    #2
                  </div>
                </div>

                {/* Content for the first column */}
               
              </div>

              {/* Second Column */}
              <div className="bg-gray-200 rounded-[10px] p-2">
                {/* Content for the second column */}
                <p className="text-l md:text-md text-cyan-600 ">
                 Our Vision
                </p>
                 <p className="text-sm md:text-md text-blue-950">
                 To be Africa’s most customer-centric ICT company, where customers can find and discover anything they might need, imagine and dream
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatWeDo;
