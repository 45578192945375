import React from 'react';
import Laptop from '../assets/Rectangle 10.png';
import {
    FaArrowRight


} from "react-icons/fa";
const LayOut = () => {
    return (


        <div className="relative flex flex-col md:flex-row p-4 md:m-10 md:p-10 bg-gray-100 rounded-lg">
            {/* First column */}
            <div className="w-full md:w-2/3 md:p-2 p-2">
                {/* Image that takes up the whole width on larger screens */}
             <img src={Laptop} alt="Your Image" className="w-full rounded-sm pr-4 " />

                {/* Content for the first column */}
            </div>

            {/* Second column */}
            <div className="w-full md:w-1/3  md:p-2 p-2">
                {/* Content for the second column */}
                <p className="text-2xl font-bold">
                    <span style={{ color: "#3287C1" }}> \ </span>
                    <span style={{ color: "black" }}> About us</span>{" "}
                    <span style={{ color: "#3287C1" }}>\</span>
                </p>
                 <p className="text-lg ">
          <div className="text-cyan-600 text-4xl font-black mt-4 my-4">
            Delivering ICT<br />Beyond Imagination
          </div>
        </p>
                <p className="text-lg ">
                    We are a fast-growing ICT firm headquartered in Lilongwe, serving
                    locally and outside Malawi.
                </p>
                <p className="text-lg">
                    The firm is based on high technological research and innovations to
                    deliver its services to the Government, NGOs, and Private Sector.
                </p>
                <p className="text-lg mt-4">
                    The research and innovations build up our capacity to updated
                    solutions in the ICT industry, competitive in Media Production,
                    Systems Development, Websites, Mobile, Graphics, Security Systems,
                    Software's, Internet & Networks, ICT Equipment Supply, Maintenance &
                    Upgrades, Consultancy, and Solar & Power Backup Systems.
                </p>
            </div>

            {/* Arrow icon */}
     {/* Arrow icon (Only visible on mobile devices) */}
      <div className="hidden md:flex items-center justify-center absolute md:bottom-5 left-1/2 transform -translate-x-1/2 mb-4 md:-mt-4">
        <h6 className="font-medium text-white rounded-full bg-[#3287C1] h-14 w-14 flex items-center justify-center">
          <FaArrowRight className="" />
        </h6>
      </div>
      {/* Arrow icon (Visible only on mobile devices) */}
      <div className="flex md:hidden items-center justify-center absolute bottom-5 left-1/2 transform -translate-x-1/2  -mb-8 tp-10">
        <h6 className="font-medium text-white rounded-full bg-[#3287C1] h-14 w-14 flex items-center justify-center">
          <FaArrowRight className="" />
        </h6>
      </div>
    </div>

        // <div className="flex">
        //   <div className="w-2/3 p-4 bg-gray-300">
        //     {/* Content for the first column */}
        //     First Column (8 columns)
        //   </div>
        //   <div className="w-1/3 p-4 bg-gray-400">
        //     {/* Content for the second column */}
        //     Second Column (4 columns)
        //   </div>
        // </div>
    );
};

export default LayOut;

