

import React from "react";
import { Fade } from "react-reveal";
import Security from "../assets/security.png";
import Media2 from "../assets/Media.png";
import Solar3 from "../assets/solar energy and power backup systems.png";
import Graphics4 from "../assets/CPU.png";
import Computer5 from "../assets/computers,accesories and softwares.png";
import Network from "../assets/network and internet.png";
import Webapps from "../assets/app, web and system development & intergration.png";
import Traning from "../assets/training, consultancy and support.png";

const Card = ({ imgSrc, title, description }) => {
  return (
    <div className="bg-neutral-100 rounded-lg p-2 flex flex-col h-full">
      <div className="bg-neutral-100 rounded-2xl p-4 md:p-6 flex-grow">
        <div className="w-16 md:w-20 h-16 md:h-20 rounded-2xl mb-2 md:mb-4">
          <img
            className="w-12 h-18 object-cover rounded-sm"
            src={imgSrc}
            alt="Small Image"
          />
        </div>
        <div className="text-blue-950 text-lg md:text-xl font-extrabold leading-relaxed mb-2 md:mb-4">
          {title}
        </div>
        <div className="bg-cyan-600 h-1 mt-2 md:mt-4 w-12 md:w-20"></div>
        <div className="text-black text-base md:text-base font-medium leading-relaxed mt-2 md:mt-4">
          {description}
        </div>
      </div>
    </div>
  );
};

const AnimatedCard = ({ imgSrc, title, description }) => {
  return (
    <Fade bottom>
      <Card imgSrc={imgSrc} title={title} description={description} />
    </Fade>
  );
};

const GridExpatise = () => {
  const cardsData = [
    {
      imgSrc: Security,
      title: "SECURITY SYSTEMS",
      description:
        "We supply, install and maintain security systems for residential, offices, warehouses, construction sites and anywhere a client may want",
    },
    {
      imgSrc: Media2,
      title: "MEDIA",
      description:
        "We supply, install and maintain security systems for residential, offices, warehouses, construction sites and anywhere a client may want",
    },
    {
      imgSrc: Solar3,
      title: "SOLAR ENERGY AND POWER BACKUP SYSTEMS",
      description:
        "We supply, install and maintain security systems for residential, offices, warehouses, construction sites and anywhere a client may want",
    },
    {
      imgSrc: Graphics4,
      title: "GRAPHICS & GAMING PC UPGRADE AND MAINTENANCE",
      description:
        "We supply, install and maintain security systems for residential, offices, warehouses, construction sites and anywhere a client may want",
    },
    {
      imgSrc: Computer5,
      title: "COMPUTERS, ACCESSORIES AND SOFTWARES",
      description:
        "It is our personal responsibility to satisfy and strive to exceed the expectations of our customers",
    },
    {
      imgSrc: Network,
      title: "NETWORK AND INTERNET",
      description:
        "It is our personal responsibility to satisfy and strive to exceed the expectations of our customers",
    },
    {
      imgSrc: Webapps,
      title: "TRAINING, CONSULTANCY AND SUPPORT",
      description:
        "It is our personal responsibility to satisfy and strive to exceed the expectations of our customers",
    },
    {
      imgSrc: Traning,
      title: "CUSTOMER SATISFACTION",
      description:
        "It is our personal responsibility to satisfy and strive to exceed the expectations of our customers",
    },
  ];
  return (
    <div className="w-full bg-[#3287C1] px-2 m-2 md:m-2">
      <div className="max-w-[100%] px-2 py-10 bg-opacity-10 rounded-lg flex flex-col justify-start items-center gap-4 md:flex-row md:gap-8 m-2">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-8 px-4 h-full">
          {cardsData.map((card, index) => (
            <div key={index} className="h-full">
              <AnimatedCard
                imgSrc={card.imgSrc}
                title={card.title}
                description={card.description}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GridExpatise;
