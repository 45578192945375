import React from 'react';
import Typed from 'react-typed';
import HeroImg from '../assets/Hero Image.png';
import Fade from 'react-reveal/Fade';

const Hero = () => {
  return (
    <div className="bg-white p-2 text-[#3287C1] py-2 md:py-2 px-4 mx-2">
      {/* Mobile View */}
      <div className="md:hidden">
        <Fade>
          <p className="text-md text-black"> \ We Are Here \</p>
        </Fade>
        <Fade>
          <h1 className="text-4xl font-bold md:text-4xl mb-4">Bridging Life<span className="text-black">&nbsp;&</span><br /><p>Technology</p></h1>
        </Fade>
        <Fade>
          <button className="bg-[#3287C1] text-sm text-white py-2 px-2 rounded-xl font-semibold shadow-lg hover:shadow-xl transition duration-300 ease-in-out" style={{ borderBottomLeftRadius: 0 }}>
            Learn More
          </button>
        </Fade>
        <div className="my-2">
          <Fade>
            <img
              src={HeroImg}
              alt="Your Image"
              className="w-full h-auto min-h-[190px] object-cover"
            />
          </Fade>
        </div>
      </div>

      {/* Desktop View */}
      <div className="hidden md:block">
        <div className="container mx-auto flex justify-between items-center">
          <div className="w-1/2 pr-12">
            <Fade>
              <p className="text-2xl">
                <span className="text-[#3287C1]"> \ </span><span className="text-black font-bold"> We Are Here</span> <span className="text-[#3287C1]">\</span>
              </p>
            </Fade>
            <Fade>
              <h1 className="text-6xl font-black font-weight-bolder   mb-4 p-2">
                Bridging Life<span className="text-black">&nbsp;&</span><br /><p className='py-2'>Technology</p>
              </h1>
            </Fade>
            <Fade>
              <button className="text-3xl bg-[#3287C1] text-white py-2 px-8 rounded-xl font-semibold shadow-lg hover:shadow-xl transition duration-300 ease-in-out" style={{ borderBottomLeftRadius: 0 }}>
                Learn More
              </button>
            </Fade>
          </div>

          <div className="w-3/4 flex justify-center relative">
            <Fade>
              <div className="w-full h-106 flex items-center justify-center relative">
                <img src={HeroImg} alt="Your Image" className="w-full h-full object-contain" />
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
